import { Language } from '@/i18n'

export const dataURLtoBlob = async (dataURL: string) => {
  const response = await fetch(dataURL)
  return await response.blob()
}
export const debounce = <Params extends unknown[], Result = unknown>(
  func: (...args: Params) => Promise<Result> | void,
  timeout = 300,
): ((...args: Params) => Promise<Result>) => {
  let timer: NodeJS.Timeout
  return (...args: Params) =>
    new Promise((resolve) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const result = func(...args)
        if (result) {
          resolve(result)
        }
      }, timeout)
    })
}

export const getDayName = (dayNumber: number, locale = Language.EN, format: 'long' | 'short' | 'narrow' = 'long') => {
  const date = new Date(Date.UTC(2021, 0, 4 + dayNumber)) // 2021-01-03 is a Monday

  let dayName = new Intl.DateTimeFormat(locale, { weekday: format }).format(date)
  dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1)

  return dayName
}

export const leftPadTime = (str: string | number) => {
  const value = typeof str === 'string' ? str : str.toString()
  return value.length < 2 ? '0' + value : value
}
