export enum SprintStatus {
  ACTIVE = 'active',
  CREATED = 'created',
  COMPLETED = 'completed',
}

export interface ISprint {
  name: string
  goal: string
  start_date: string
  end_date: string
  status: SprintStatus
}

export interface ISprintResponse extends ISprint {
  uuid: string
  sprint_id: number
  project_id: string
}

export interface IMoveTicketsRequest {
  source_sprint_uuid: string
  target_sprint_uuid?: string
  to_backlog?: boolean
  status_uuids: string[]
}
