import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { Button } from 'flowbite-react'

import { useCreateSprint, useGetSprints, useUpdateSprint } from '@/projects/queries/sprints'

import { SprintStatus } from '@/projects/models/ISprint'

export interface NoActiveSprintProps {}

export const NoActiveSprint: React.FC<NoActiveSprintProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'sprint' })
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const { data: sprints } = useGetSprints(projectId, teamspaceId)
  const { mutateAsync: createSprint, isPending: isSprintCreating } = useCreateSprint(projectId, teamspaceId)

  const nextSprint = useMemo(() => {
    return sprints
      .sort((a, b) => {
        return a.sprint_id > b.sprint_id ? 1 : -1
      })
      .find((sprint) => sprint.status === SprintStatus.CREATED)
  }, [sprints])

  const { mutateAsync: patchSprint, isPending } = useUpdateSprint(projectId, teamspaceId, nextSprint?.uuid)

  return (
    <div className="flex flex-col items-center gap-2 p-3">
      {t('noActiveSprintLabel')}
      <Button
        size="xs"
        isProcessing={isPending || isSprintCreating}
        onClick={async () => {
          if (!nextSprint) {
            const latestSprint = sprints
              .sort((a, b) => dayjs(b.end_date).unix() - dayjs(a.end_date).unix())
              .find((sprint) => !!sprint.end_date)

            await createSprint({
              start_date: dayjs(latestSprint?.end_date).add(1, 'day').format('YYYY-MM-DD'),
              end_date: dayjs(latestSprint?.end_date).add(2, 'week').add(1, 'day').format('YYYY-MM-DD'),
              goal: '',
              name: `Sprint ${sprints?.length + 1}`,
              status: SprintStatus.ACTIVE,
            })
            return
          }
          patchSprint({ status: SprintStatus.ACTIVE })
        }}
      >
        {t('startSprintLabel')}
      </Button>
    </div>
  )
}

export default NoActiveSprint
