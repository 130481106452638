import cx from 'clsx'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { Button } from 'flowbite-react/components/Button'
import Skeleton from '@/components/Skeleton'
import Datepicker from '@/components/Datepicker'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Status from '@/components/Status'
import Priority from '@/components/Priority'
import UserSelect from '@/projects/components/UserSelect'
import Reminder, { ReminderValue } from '@/projects/components/Reminder'
import Recurring, { RecurringValue } from '@/projects/components/Recurring'
import Attachments from '../Attachments'
import { TrashIcon } from '@heroicons/react/24/outline'

import { useDeleteTicket, useGetProject, useGetTicket, usePatchTicket, useRemind } from '@/projects/queries'
import { useToggleOpen } from '@/utils/hooks/useToggle'
import routes from '@/utils/routes'
import { debounce } from '@/utils'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'
import { useGetReminderBody } from '@/projects/hooks/useGetReminderBody'
import { useGetRecurringBody } from '@/projects/hooks/useGetRecurringBody'

import { ReminderFrequency } from '@/projects/models/IProject'
import { UserType } from '@/auth/models/IUser'

export interface TicketContentProps {}

type Inputs = {
  description: string
  title: string
}

const MAX_DESCRIPTION_LENGTH = 5000
const MAX_DESCRIPTION_HEIGHT = 330

export const TicketContent: React.FC<TicketContentProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })
  const { ticketId, projectId, teamspaceId } = useParams<{ ticketId: string; projectId: string; teamspaceId: string }>()
  const isWide = useIsWideScreen()

  const { data: ticket } = useGetTicket(ticketId, teamspaceId, projectId)
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: remind } = useRemind(teamspaceId, projectId)
  const getReminderValue = useGetReminderBody()
  const getRecurringBody = useGetRecurringBody()

  const navigate = useNavigate()

  const defaultValue = useMemo<Inputs>(() => {
    return {
      description: ticket?.description || '',
      title: ticket?.title || '',
    }
  }, [ticket])

  const reminderValue = useMemo<ReminderValue | null>(() => {
    const date = ticket?.reminder_settings?.datetime ? new Date(ticket?.reminder_settings?.datetime) : null
    return ticket
      ? {
          frequency: ticket?.reminder_settings?.frequency || ReminderFrequency.NOW,
          date: ticket?.reminder_settings?.datetime ? new Date(ticket?.reminder_settings?.datetime) : null,
          day: ticket?.reminder_settings?.day_of_week ?? undefined,
          hour: ticket?.reminder_settings?.hour ?? date?.getHours() ?? undefined,
          minute: ticket?.reminder_settings?.minute ?? date?.getMinutes() ?? undefined,
        }
      : null
  }, [ticket])
  const recurringValue = useMemo<RecurringValue | null>(() => {
    return ticket
      ? {
          frequency: ticket?.recurring_params?.frequency,
          daysOfWeek: ticket?.recurring_params?.days_of_week ?? undefined,
          daysOfMonth: ticket?.recurring_params?.days_of_month ?? undefined,
          hour: ticket?.recurring_params?.hours ?? undefined,
          minute: ticket?.recurring_params?.minutes ?? undefined,
        }
      : null
  }, [ticket])

  const { register, getFieldState, watch, reset, setFocus } = useForm<Inputs>({
    values: defaultValue,
  })
  const descriptionRef = useRef<HTMLTextAreaElement>()
  const numberRef = useRef<HTMLDivElement>(null)

  const { isOpen: isDeleteOpen, toggleOpen: toggleDelete } = useToggleOpen()

  const { mutateAsync: patchTicket } = usePatchTicket(ticket?.teamspace_id, ticket?.project_id, ticket?.uuid)
  const { mutateAsync: deleteTicket, isPending } = useDeleteTicket(
    ticket?.teamspace_id,
    ticket?.project_id,
    ticket?.uuid,
  )

  const description = watch('description')
  const descriptionField = register('description')
  const descriptionState = getFieldState('description')
  const handleInputDescription = () => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = ''
      let newHeight = descriptionRef.current.scrollHeight + 40
      if (newHeight > MAX_DESCRIPTION_HEIGHT) {
        newHeight = MAX_DESCRIPTION_HEIGHT
      }
      descriptionRef.current.style.height = newHeight + 'px'
    }
  }

  useEffect(() => {
    handleInputDescription()
  }, [description])

  const handleDeleteTicket = async () => {
    await deleteTicket()
    sendEvent('delete-ticket')
    navigate(generatePath(routes.private.board, { teamspaceId: ticket?.teamspace_id, projectId: ticket?.project_id }))
  }

  const saveTitle = (title: string) => {
    patchTicket({ title })
  }

  const saveTitleDebounced = debounce(saveTitle, 2000)

  useLayoutEffect(() => {
    const ticketTitleElement = document.getElementById('ticket-title')
    if (ticket && numberRef.current && ticketTitleElement) {
      ticketTitleElement.style.paddingLeft = `${numberRef.current?.scrollWidth + 4}px`
    }
  }, [ticket])

  const titleProps = register('title')

  const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    titleProps.onChange(event)
    saveTitleDebounced(event.target.value)
  }

  return (
    <div
      className={cx('flex h-full flex-col gap-3', {
        'max-w-2xl': isWide,
      })}
    >
      <div className="flex items-center justify-between gap-2">
        <Skeleton isActive={!!ticket}>
          <div className={cx('relative w-full text-neutral-600 dark:text-neutral-200')}>
            <div
              className="absolute left-0 px-0.5"
              ref={numberRef}
              onClick={() => {
                setFocus('title')
              }}
            >
              {ticket?.ticket_id}:
            </div>
            <input
              className="w-full bg-transparent"
              id="ticket-title"
              {...titleProps}
              onChange={onChangeTitle}
              maxLength={70}
              data-amp-mask
            />
          </div>
        </Skeleton>
        <div className="flex gap-2">
          <Skeleton isActive={!!ticket} className="w-7">
            <Priority priority={ticket?.priority} onChange={(priority) => patchTicket({ priority })} />
          </Skeleton>
          {project.user_type === UserType.regular && (
            <UserSelect
              onSelect={(assignee) => {
                patchTicket({ assignee })
              }}
              value={ticket?.assignee}
            />
          )}
        </div>
      </div>

      <div className="flex flex-wrap gap-3">
        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('statusLabel')}</span>
          <Skeleton isActive={!!ticket} className="w-24">
            <Status status={ticket?.status} onChange={(status) => patchTicket({ status })} />
          </Skeleton>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('startDateLabel')}</span>
          <Skeleton isActive={!!ticket}>
            <Datepicker
              onChange={(value) => {
                patchTicket({ start_date: value ? dayjs(value).format('YYYY-MM-DD') : null })
              }}
              placeholder={t('notSetPlaceholder')}
              value={ticket?.start_date ? new Date(ticket?.start_date) : undefined}
              isClearable
            />
          </Skeleton>
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('dueDateLabel')}</span>
          <Skeleton isActive={!!ticket}>
            <Datepicker
              onChange={(value) => {
                patchTicket({ due_date: value ? dayjs(value).format('YYYY-MM-DD') : null })
              }}
              placeholder={t('notSetPlaceholder')}
              value={ticket?.due_date ? new Date(ticket?.due_date) : undefined}
              isClearable
            />
          </Skeleton>
        </div>
      </div>
      <Skeleton isActive={!!ticket} className="h-36 w-full">
        <div className="relative w-full">
          <textarea
            className="transition-color mah-h-[300px] min-h-36 w-full rounded border-none bg-wall-secondary-bg-light p-2 pb-10 ring-2 ring-transparent hover:ring-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:bg-wall-secondary-bg-dark"
            {...descriptionField}
            maxLength={MAX_DESCRIPTION_LENGTH}
            ref={(ref) => {
              descriptionField.ref(ref)
              descriptionRef.current = ref || undefined
            }}
            data-amp-mask
            onInput={handleInputDescription}
          />
          {descriptionState.isDirty && (
            <div className="absolute bottom-3 right-2 flex justify-end gap-2">
              <Button
                size="xs"
                color="red"
                onClick={() => {
                  reset()
                }}
              >
                {t('cancelLabel')}
              </Button>
              <Button
                size="xs"
                color="primary"
                onClick={() => {
                  patchTicket({ description })
                  reset({ description })
                }}
              >
                {t('saveLabel')}
              </Button>
            </div>
          )}
        </div>
      </Skeleton>

      <div
        className={cx('flex grow flex-col gap-2', {
          'justify-between': !isWide,
        })}
      >
        <div className="flex flex-col gap-5">
          <Skeleton isActive={!!ticket} className="h-14 w-full">
            <Attachments />
          </Skeleton>
          <div className="flex flex-wrap gap-2">
            {!!reminderValue && (
              <div className="flex">
                <Reminder
                  reminder={reminderValue}
                  isSet={!!ticket?.reminder_settings}
                  onChange={(reminder) => {
                    if (!reminder) {
                      patchTicket({ reminder_settings: null })
                      return
                    }
                    if (reminder.frequency === ReminderFrequency.NOW && ticketId) {
                      remind(ticketId)
                    } else {
                      patchTicket({ reminder_settings: getReminderValue(reminder) })
                    }
                  }}
                />
              </div>
            )}
            {!ticket?.is_recurring && recurringValue && (
              <div className="flex">
                <Recurring
                  recurring={recurringValue}
                  isSet={!!ticket?.recurring_params}
                  onChange={(recurring) => {
                    if (!recurring) {
                      patchTicket({ recurring_params: null })
                      return
                    }
                    patchTicket({ recurring_params: getRecurringBody(recurring) })
                  }}
                />
              </div>
            )}
            {ticket?.recurring_ticket_uuid ? (
              <Button
                color="secondary"
                size="xs"
                onClick={() =>
                  navigate(
                    generatePath(routes.private.ticket, {
                      ticketId: ticket?.recurring_ticket_uuid,
                      teamspaceId,
                      projectId,
                    }),
                    {
                      state: {
                        from: location.pathname,
                      },
                    },
                  )
                }
              >
                {t('recurring.parrentTaskLabel')}
              </Button>
            ) : null}
          </div>
        </div>

        <div
          className={cx('flex items-end justify-end', {
            'mt-5': isWide,
          })}
        >
          <button onClick={toggleDelete} className="text-red-500 hover:text-red-700">
            <TrashIcon className="h-6 w-6" />
          </button>
          <ConfirmationDialog
            isOpen={isDeleteOpen}
            onClose={toggleDelete}
            onConfirm={handleDeleteTicket}
            confirmationTitle={t('deleteTitle', { number: ticket?.ticket_id })}
            isLoading={isPending}
          />
        </div>
      </div>
    </div>
  )
}

export default TicketContent
