import { generatePath, Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { Button } from 'flowbite-react'
import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Posts from '@/home/components/Posts'
import Tickets from '@/home/components/Tickets'
import AddNewTicket from '@/components/AddNewTicket'

import routes from '@/utils/routes'
import { useGetProject } from '@/projects/queries'
import { showChatTip as showChatTipAtom } from '@/home/store'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { useGetMe } from '@/auth/queries'

import { UserType } from '@/auth/models/IUser'

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'home' })
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const navigate = useNavigate()

  const [showChatTip, setShowChatTip] = useAtom(showChatTipAtom)

  const { data: project } = useGetProject(projectId, teamspaceId)
  const { data: me } = useGetMe()

  return (
    <>
      <div className="flex flex-col gap-3 p-3">
        {!project.chat_id && showChatTip && me.uuid === project.owner_id && (
          <div className="relative flex items-center justify-between gap-2 rounded bg-wall-main-light px-3 py-2 pr-8 text-xs text-white dark:bg-wall-main-dark">
            <div>{t('chatSetting.title')}</div>
            <Button
              size="xs"
              color="green"
              className="grow"
              onClick={() => {
                setShowChatTip(false)
                sendEvent('open-chat-setting-from-home')
                navigate(generatePath(routes.private.projectSettings, { teamspaceId, projectId }))
              }}
            >
              {t('chatSetting.goLabel')}
            </Button>
            <button
              className="absolute right-1 top-1 rounded p-0.5 transition-colors hover:bg-wall-gray-neutral/30"
              onClick={() => {
                setShowChatTip(false)
                sendEvent('close-chat-setting-from-home')
              }}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        )}
        <Posts />
        <div className="flex items-center justify-between gap-2 border-b border-wall-gray-light py-1 font-medium dark:border-wall-gray-dark">
          <p>{t('assignedToMeLabel')}</p>
          <Link
            to={generatePath(routes.private.board, { teamspaceId, projectId })}
            className="flex items-center gap-1 text-wall-secondary"
          >
            {t('goToWallLabel')} <ArrowRightIcon className="h-4 w-4" />
          </Link>
        </div>
        <Tickets />
        {project.user_type === UserType.regular && <AddNewTicket />}
      </div>
    </>
  )
}

export default Home
