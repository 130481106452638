import { useTranslation } from 'react-i18next'
import cx from 'clsx'
import { useParams } from 'react-router-dom'
import { validate } from 'uuid'
import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import Select from '@/components/Select'
import { Button, Drawer } from 'flowbite-react'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { useCreateSprint, useGetSprints, useUpdateSprint, useMoveTickets } from '@/projects/queries/sprints'
import { useGetProject, useGetTicketList } from '@/projects/queries'

import { SprintStatus } from '@/projects/models/ISprint'
import { StatusCategory } from '@/projects/models/IProject'

export interface SprintSettingsProps {
  isOpen: boolean
  handleClose: () => void
}

const enum MoveOption {
  BACKLOG = 'backlog',
  NEW_SPRINT = 'new_sprint',
}

export const SprintSettings: React.FC<SprintSettingsProps> = ({ isOpen, handleClose }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const { t } = useTranslation('projects', { keyPrefix: 'sprint' })
  const isIos = useIsIos()

  const [moveToOption, setMoveToOption] = useState<string | MoveOption>(MoveOption.BACKLOG)

  const { data: project } = useGetProject(projectId, teamspaceId)
  const id = project.active_sprint_uuid as string
  const { data: sprints } = useGetSprints(projectId, teamspaceId)
  const { mutateAsync: uppdateSprint, isPending: isSprintUpdating } = useUpdateSprint(projectId, teamspaceId, id)
  const { mutateAsync: createSprint, isPending: isSprintCreating } = useCreateSprint(projectId, teamspaceId)
  const { mutateAsync: moveTickets, isPending: isTicketsMoving } = useMoveTickets(projectId, teamspaceId)
  const { data: tickets } = useGetTicketList(projectId, teamspaceId, { sprint_status: SprintStatus.ACTIVE })

  const moveToOptions = useMemo(() => {
    const awailableSprints = sprints?.filter((sprint) => sprint.status === SprintStatus.CREATED)
    return [
      { label: t('backlogLabel'), value: MoveOption.BACKLOG },
      ...(awailableSprints || []).map((sprint) => ({
        label: sprint.name,
        value: sprint.uuid,
      })),
      { label: t('newSprintLabel'), value: MoveOption.NEW_SPRINT },
    ]
  }, [sprints, t])

  const hasUncompletedTickets = useMemo(() => {
    const completeStatuses = project.settings.statuses
      .filter((status) => !!status.category && status.category === StatusCategory.Done)
      .map((status) => status.uuid)
    return !!tickets?.some((ticket) => !completeStatuses.includes(ticket.status))
  }, [tickets, project])

  const handleCompleteSprint = useCallback(async () => {
    let moveTo = moveToOption === MoveOption.BACKLOG ? MoveOption.BACKLOG : (moveToOption as string)
    await uppdateSprint({
      status: SprintStatus.COMPLETED,
    })

    if (hasUncompletedTickets) {
      if (moveToOption === MoveOption.NEW_SPRINT) {
        const latestSprint = sprints
          .sort((a, b) => dayjs(b.end_date).unix() - dayjs(a.end_date).unix())
          .find((sprint) => !!sprint.end_date)

        const newSprint = await createSprint({
          start_date: dayjs(latestSprint?.end_date).add(1, 'day').format('YYYY-MM-DD'),
          end_date: dayjs(latestSprint?.end_date).add(2, 'week').add(1, 'day').format('YYYY-MM-DD'),
          goal: '',
          name: `Sprint ${sprints?.length + 1}`,
          status: SprintStatus.CREATED,
        })
        moveTo = newSprint.uuid
      }

      const statuses = project.settings.statuses
        .filter(
          (status) => !!status.category && [StatusCategory.InProgress, StatusCategory.Todo].includes(status.category),
        )
        .map((status) => status.uuid)
      if (validate(moveTo)) {
        await moveTickets({
          source_sprint_uuid: id,
          target_sprint_uuid: moveTo,
          status_uuids: statuses,
        })
      } else if (moveTo === MoveOption.BACKLOG) {
        await moveTickets({
          source_sprint_uuid: id,
          to_backlog: true,
          status_uuids: statuses,
        })
      }
    }

    handleClose()
  }, [moveToOption, sprints, project, tickets])

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      className={cx('rounded-t-md', {
        'pb-10': isIos,
      })}
      position="bottom"
    >
      <Drawer.Header title={t('settings.title')} titleIcon={() => null} />
      {isOpen && (
        <div className="flex flex-col gap-2">
          {hasUncompletedTickets && (
            <div className="flex">
              <Select
                options={moveToOptions}
                value={moveToOption}
                onChange={setMoveToOption}
                label={t('moveTicketsLabel')}
                title={t('moveTicketsLabel')}
              />
            </div>
          )}
          <div className="flex justify-end">
            <Button
              size="xs"
              color="green"
              isProcessing={isSprintUpdating || isSprintCreating || isTicketsMoving}
              onClick={handleCompleteSprint}
            >
              {t('completeSprintLabel')}
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default SprintSettings
