import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  init as initTelegramSDK,
  viewport,
  initData,
  miniApp,
  themeParams,
  backButton,
  swipeBehavior,
  mainButton,
  useSignal,
} from '@telegram-apps/sdk-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Flowbite } from 'flowbite-react'

import App from './App'

import './i18n'
import theme from './flowbiteTheme'

initTelegramSDK()
viewport
  .mount()
  .then(() => {
    viewport.expand()
    viewport.bindCssVars()
  })
  .catch((e) => console.log(e))

backButton.mount.ifAvailable()
miniApp.mount.ifAvailable()
swipeBehavior.mount.ifAvailable()
themeParams.mount.ifAvailable()
mainButton.mount.ifAvailable()
initData.restore()

miniApp.bindCssVars.ifAvailable()
themeParams.bindCssVars.ifAvailable()

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: APP_VERSION,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration({
      depth: 10,
      captureErrorCause: true,
    }),
  ],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_ENVIRONMENT,
})

const mockAdapterDefault = {
  enabled: import.meta.env.VITE_ENABLE_MOCK === 'true',
  init: false,
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false, staleTime: 5 * 60 * 1000 } },
})

export const WithMockAdapter = () => {
  const [mockAdapter, setMockAdapter] = React.useState(mockAdapterDefault)
  const isDark = useSignal(miniApp.isDark)

  useEffect(() => {
    if (mockAdapter.enabled && !mockAdapter.init) {
      ;(async () => {
        await import('./utils/mockAdapter')
        await import('./utils/mockAdapter/mock')
        setMockAdapter({ ...mockAdapter, init: true })
      })()
    }
  }, [mockAdapter])

  const app = (
    <div style={{ backgroundColor: 'var(--tg-bg-color)' }}>
      <Flowbite theme={{ mode: isDark ? 'dark' : 'light', theme }}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Flowbite>
    </div>
  )
  if (mockAdapter.enabled) {
    return mockAdapter.init ? app : <div className="h-dvh bg-wall-main-bg-light dark:bg-wall-main-bg-dark" />
  }

  return app
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WithMockAdapter />
  </React.StrictMode>,
)
