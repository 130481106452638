import { UserType } from '@/auth/models/IUser'
import { Language } from '@/i18n'
import { SprintStatus } from './ISprint'

export const enum StatusCategory {
  Todo = 'todo',
  InProgress = 'in_progress',
  Done = 'done',
}

export interface IStatus {
  name: string
  position: number
  category?: StatusCategory
}
export interface IStatusResponse extends IStatus {
  uuid: string
}

export interface IStatusRequest extends IStatus {
  uuid?: string
}

export interface IProjectCreateRequest {
  name: string
  key: string
  teamspace_id?: string
}

export interface IProjectSettingsRequest {
  statuses: IStatusRequest[]
}

export interface IProjectUpdateRequest {
  name: string
  key: string
  settings: IProjectSettingsRequest
  chat_id: number | null
  language_code: Language
  sprints_enabled: boolean
  backlog_enabled: boolean
}

export interface IProjectSettingsResponse {
  statuses: IStatusResponse[]
}

export interface IProjectResponse {
  uuid: string
  name: string
  key: string
  chat_id: number | null
  owner_id: string
  teamspace_id: string
  language_code: Language | null
  sprints_enabled: boolean
  backlog_enabled: boolean
  settings: IProjectSettingsResponse
  active_sprint_uuid: string | null
  user_type: UserType
}

export interface IProjectListItemResponse {
  uuid: string
  name: string
  key: string
  teamspace_id: string
  deleted_at: string | null
}
export type IProjectListResponse = IProjectListItemResponse[]

export interface IDeleteStatusRequestParams {
  status_uuid: string
  safe: boolean
  change_to_status_uuid?: string
}

export interface IDeleteStatusResponse {
  message: string
  ticket_count: number
}

export const enum Priority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface ICard {
  id: string
  title: string
  number: string
  status: string
  priority: Priority
  assignee: string | null
  due_date: string | null
}

export const enum ColumnType {
  status = 'status',
  sprint = 'sprint',
  backlog = 'backlog',
  board = 'board',
  newSprint = 'new_sprint',
}
export interface IColumn {
  id: string
  title: string
  color?: string
  cards: ICard[]
  type: ColumnType
  isActiveSprint?: boolean
  activeSprintUUID?: string | null
}

export const enum TicketType {
  Task = 'task',
  Bug = 'bug',
}

export const enum ReminderFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  ONCE = 'once',
  NOW = 'now',
}

export interface ReminderSettings {
  frequency: ReminderFrequency
  datetime: string | null
  day_of_week: number[] | null
  hour: number | null
  minute: number | null
}

export const enum RecurringFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export interface RecurringSettings {
  frequency: RecurringFrequency
  days_of_week: number[] | null
  days_of_month: (number | string)[] | null
  hours: number | null
  minutes: number | null
}

export interface ITicket {
  title: string
  description: string
  status: string
  priority?: Priority
  type: TicketType
  is_recurring: boolean
  assignee: string | null
  start_date: string | null
  due_date: string | null
  invite_uuid: string | null
  sprint_uuid?: string | null
  reminder_settings: ReminderSettings | null
  recurring_params: RecurringSettings | null
  in_backlog: boolean
}

export interface ITicketResponse extends ITicket {
  uuid: string
  number: number
  key: string
  ticket_id: string
  teamspace_id: string
  project_id: string
  is_recurring: boolean
  recurring_ticket_uuid: string | null
}
export interface ITicketListItemResponse {
  title: string
  uuid: string
  number: number
  status: string
  priority: Priority
  ticket_id: string
  sprint_uuid: string | null
  due_date: string | null
  assignee: string | null
  deleted_at: string | null
  in_backlog: boolean
}
export interface ITicketListRequest {
  priority?: Priority[]
  assignee_uuids?: string[]
  owner_uuids?: string[]
  status_uuids?: string[]
  sprint_status?: SprintStatus
  recurring?: boolean
}
export type ITicketListResponse = ITicketListItemResponse[]

export type IProjectStatusesResponse = IStatusResponse[]

export interface ITicketAttachment {
  uuid: string
  file: string
  filename: string
  created_at: string
  updated_at: string
  user_id: string
  comment_id: string | null
  ticket_id: string
}
export type ITicketAttachmentResponse = ITicketAttachment

export interface IInviteUserResponse {
  invite_link: string
  invite_uuid: string
}

export interface IInviteUserRequest {
  user_type: UserType
}
