import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'

import api from '@/utils/api'
import { IAuthResponse } from '../models/IAuth'
import { IUser, IUserResponse } from '../models/IUser'
import { AxiosError } from 'axios'

export const PATHS = {
  auth: '/auth',
  me: '/user/me',
}

export const useAuth = (initData?: string) => {
  return useSuspenseQuery<IAuthResponse>({
    queryKey: ['AUTH'],
    staleTime: Infinity,
    queryFn: async () => {
      const body = {
        initData,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      const response = await api.post<IAuthResponse>(PATHS.auth, body, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json',
        },
      })
      if (response.data.access_token) {
        api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`
      }

      return response.data
    },
  })
}

export const useGetMe = () => {
  return useSuspenseQuery<IUserResponse>({
    queryKey: ['ME'],
    queryFn: async () => {
      const response = await api.get<IUserResponse>(PATHS.me)
      return response.data
    },
  })
}

export const usePatchMe = () => {
  const queryClient = useQueryClient()
  return useMutation<IUserResponse, AxiosError, Partial<IUser>>({
    mutationFn: async (body) => {
      const response = await api.patch<IUserResponse>(PATHS.me, body)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ME'] })
    },
  })
}
