import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ScrollRestoration, useParams } from 'react-router-dom'
import { Dispatch, SetStateAction } from 'react'

import Card from '../Card'
import CreateCard from '../CreateCard'
import ColumnHeader from '../ColumnHeader'

import { useDroppable } from '@dnd-kit/core'

import { ColumnType, IColumn } from '@/projects/models/IProject'
import { useScrollRestorationRef } from '@/utils/hooks/useScrollRestorationRef'
import { useGetProject } from '@/projects/queries'
import { UserType } from '@/auth/models/IUser'

export type ColumnProps = {
  column: IColumn
  isWide: boolean
  isCreating: string | null
  showSwipeTip?: boolean
  setIsCreating: Dispatch<SetStateAction<string | null>>
}

const Column: React.FC<ColumnProps> = ({ column, showSwipeTip = false, isCreating, isWide, setIsCreating }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { id, cards } = column
  const { setNodeRef, active } = useDroppable({ id })
  const scrollRestorationRef = useScrollRestorationRef(`#column-${id}`)

  const { data: project } = useGetProject(projectId, teamspaceId)

  const setIsCreatingColumn: (columnId: string) => Dispatch<SetStateAction<boolean>> = (columnId) => (isCreating) => {
    setIsCreating(isCreating ? columnId : null)
  }

  return (
    <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
      <ScrollRestoration />
      <div
        ref={column.type === ColumnType.newSprint ? undefined : setNodeRef}
        className={twMerge(
          cx('group relative h-full rounded', active ? 'h-auto' : 'h-full', {
            'animate-pulse': active?.data?.current?.sortable?.containerId === id,
            'min-w-[320px] max-w-[320px]': isWide,
            'min-w-[calc(100vw-40px)]': !isWide,
          }),
        )}
      >
        <div className="flex w-full flex-col gap-3 rounded bg-wall-main-bg-light dark:bg-wall-main-bg-dark">
          <ColumnHeader column={column} />
          {!isWide && !active && column.type !== ColumnType.newSprint && project.user_type === UserType.regular && (
            <CreateCard
              column={column}
              isCreating={id === isCreating}
              setIsCreating={setIsCreatingColumn(id)}
              isWide={isWide}
            />
          )}
        </div>
        <div
          ref={scrollRestorationRef}
          id={`column-${id}`}
          className={twMerge(
            cx(
              'disable-lens relative -mx-[2px] flex select-none flex-col overflow-auto px-[2px]',
              {
                'overflow-hidden pb-[172px] pt-3': isCreating && !isWide,
                'pb-[106px] pt-3': !isCreating && !isWide,
                'pb-[60px] pt-3': isWide,
              },
              active && !isWide ? 'h-auto gap-0 py-3' : 'h-full gap-3',
            ),
          )}
        >
          {cards.filter(Boolean).map((card) => (
            <Card key={card.id} card={card} column={column} isWide={isWide} />
          ))}
          {isWide && column.type !== ColumnType.newSprint && (
            <CreateCard
              isCreating={id === isCreating}
              setIsCreating={setIsCreatingColumn(id)}
              isWide={isWide}
              column={column}
            />
          )}
          {!active && !isWide && <div className="min-h-8" />}
        </div>
        {showSwipeTip && !active && !isWide && !isCreating && (
          <div
            className="absolute left-1/2 top-1/2 h-[136px] w-[136px] -translate-x-1/2 -translate-y-1/2 bg-gray-500 bg-opacity-50"
            style={{ mask: 'url(/swipe.webp) no-repeat center', maskSize: 'contain' }}
            onMouseDown={(e) => e.preventDefault()}
            onTouchStart={(e) => e.preventDefault()}
          />
        )}
      </div>
    </SortableContext>
  )
}

export default Column
