import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { ScopeProvider } from 'jotai-scope'

import Filter from '@/projects/components/Filter'
import BoardBase from '@/projects/components/Board'
import NoActiveSprint from '@/projects/components/NoActiveSprint'

import { withLoader } from '@/utils/hocs/withLoader'
import { useGetProject, useGetTicketList } from '@/projects/queries'
import { filter as filterAtom, columns as columnsAtom } from '@/projects/store'
import { useStatusCategoryOptions } from '../Statuses/constants'

import { ColumnType, IColumn } from '@/projects/models/IProject'
import { SprintStatus } from '@/projects/models/ISprint'

export interface BoardComponentProps {}

const BoardComponent: React.FC<BoardComponentProps> = () => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const [filter] = useAtom(filterAtom)

  const { data: project } = useGetProject(projectId, teamspaceId)
  const {
    data: tickets,
    isFetching: isTicketsFetching,
    isLoading,
    isPending,
  } = useGetTicketList(projectId, teamspaceId, {
    ...filter,
    sprint_status: project.sprints_enabled ? SprintStatus.ACTIVE : undefined,
  })
  const statusCategoryOptions = useStatusCategoryOptions()

  const [, setColumns] = useAtom(columnsAtom)

  useEffect(() => {
    const columnsInit = project.settings.statuses.map<IColumn>((status) => {
      const categoryOption = statusCategoryOptions.find((option) => option.value === status.category)
      return {
        id: status.uuid,
        title: status.name,
        color: categoryOption?.color,
        type: ColumnType.status,
        activeSprintUUID: project.active_sprint_uuid,
        cards:
          tickets
            ?.map((ticket) => ({ ...ticket, id: ticket.uuid, number: ticket.ticket_id }))
            ?.filter((ticket) => {
              const isSameStatus = ticket.status === status.uuid

              const isOnBoard = project.backlog_enabled && !project.sprints_enabled ? !ticket.in_backlog : true

              return isSameStatus && isOnBoard
            }) || [],
      }
    })

    setColumns(columnsInit)
  }, [tickets, project])

  if (project.sprints_enabled && !project.active_sprint_uuid) {
    return <NoActiveSprint />
  }

  return (
    <>
      <Filter />
      <BoardBase isLoading={isLoading || isTicketsFetching || isPending} />
    </>
  )
}

export const Board = withLoader(
  () => (
    <ScopeProvider atoms={[columnsAtom, filterAtom]}>
      <BoardComponent />
    </ScopeProvider>
  ),
  <div className="h-full w-full animate-pulse rounded bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />,
)

export default Board
