import { useCallback, useMemo, useState } from 'react'
import { useDndContext } from '@dnd-kit/core'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

import Column from '../Column'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

import { useScrollRestorationRef } from '@/utils/hooks/useScrollRestorationRef'
import { useIsIos, useIsAndroid } from '@/utils/hooks/useIsMobileDevice'

import { ColumnType, IColumn } from '@/projects/models/IProject'

export interface ColumnsProps {
  columns: IColumn[]
  id: string
  isLoading?: boolean
  isWide: boolean
}
const minSwipeDistance = 50
const PADDING_WIDTH = 12

export const Columns: React.FC<ColumnsProps> = ({ columns, id, isWide, isLoading }) => {
  const { active } = useDndContext()
  const isIos = useIsIos()
  const isAndroid = useIsAndroid()
  const scrollRestorationRef = useScrollRestorationRef(`#board-${id}`)

  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  const [isCreating, setIsCreating] = useState<string | null>(null)

  const [isScrolledStart, setIsScrolledStart] = useState(true)
  const [isScrolledEnd, setIsScrolledEnd] = useState(false)

  const scrollOnAndroid = (currentScrollLeft: number, offset: number, direction: 'left' | 'right') => {
    const start = currentScrollLeft
    const distance = offset
    const duration = 300
    const startTime = performance.now()

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)
      const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3)
      scrollRestorationRef.current!.scrollLeft =
        start + distance * easeOutCubic(progress) * (direction === 'left' ? -1 : 1)

      if (progress < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  const handleRightScroll = () => {
    if (scrollRestorationRef.current) {
      const offset =
        (scrollRestorationRef.current?.children?.[1]?.clientWidth || 0) + PADDING_WIDTH - (isScrolledStart ? 8 : 0)
      const currentScrollLeft = scrollRestorationRef.current.scrollLeft
      const targetScrollLeft = currentScrollLeft + offset

      // Prevent scrolling beyond the maximum scroll width
      if (scrollRestorationRef.current.scrollWidth - targetScrollLeft < PADDING_WIDTH) {
        return
      }

      if (isAndroid) {
        scrollOnAndroid(currentScrollLeft, offset, 'right')
      } else {
        scrollRestorationRef.current.scrollTo({
          left: targetScrollLeft,
          behavior: 'smooth',
        })
      }
    }
  }

  const handleLeftScroll = () => {
    const offset =
      (scrollRestorationRef.current?.children?.[1]?.clientWidth || 0) + PADDING_WIDTH - (isScrolledEnd ? 8 : 0)

    const scrollLeft = (scrollRestorationRef?.current?.scrollLeft || 0) - offset
    const currentScrollLeft = scrollRestorationRef.current?.scrollLeft || 0

    if (isAndroid) {
      scrollOnAndroid(currentScrollLeft, offset, 'left')
    } else {
      scrollRestorationRef.current?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }

  const onTouchMove = useCallback((e: TouchEvent) => setTouchEnd(e.targetTouches[0].clientX), [])

  const onMouseMove = useCallback((e: MouseEvent) => {
    setTouchEnd(e.clientX)
  }, [])

  const onMoveEnd = useCallback(() => {
    if (scrollRestorationRef.current) {
      scrollRestorationRef.current.removeEventListener('touchmove', onTouchMove)
      scrollRestorationRef.current.removeEventListener('mousemove', onMouseMove)
    }
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      handleRightScroll()
    }
    if (isRightSwipe) {
      handleLeftScroll()
    }
  }, [touchStart, touchEnd])

  const handleTouchStart = () => {
    setTouchEnd(null)
    if (scrollRestorationRef.current) {
      scrollRestorationRef.current.addEventListener('touchmove', onTouchMove)
      scrollRestorationRef.current.addEventListener('mousemove', onMouseMove)
    }
  }

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    handleTouchStart()
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onMouseMoveStart = (e: React.MouseEvent<HTMLDivElement>) => {
    handleTouchStart()
    setTouchStart(e.clientX)
  }

  const handleScroll = useCallback(() => {
    setIsScrolledStart((scrollRestorationRef?.current?.scrollLeft || 0) <= 5)
    setIsScrolledEnd(
      (scrollRestorationRef?.current?.scrollLeft || 0) + (scrollRestorationRef.current?.clientWidth || 0) + 5 >=
        (scrollRestorationRef?.current?.scrollWidth || 0),
    )
  }, [])

  const props = useMemo(() => {
    if (isWide) {
      return {}
    }
    return {
      onTouchStart,
      onMouseDown: onMouseMoveStart,
      onTouchEnd: onMoveEnd,
      onMouseUp: onMoveEnd,
    }
  }, [touchStart, touchEnd])

  return (
    <div
      className={twMerge(
        cx('relative flex h-full gap-3 transition-[flex-direction]', {
          'flex-col gap-0 px-3': !isWide && !!active,
          'overflow-x-auto overflow-y-hidden px-3': isWide,
          'touch-none': isCreating,
          'overflow-hidden': !isWide,
        }),
      )}
      {...props}
      onScroll={handleScroll}
      ref={scrollRestorationRef}
    >
      {!active && !isWide && <div className="" />}
      {columns
        .filter((column) => (!!active && !isWide ? column.type !== ColumnType.newSprint : true))
        .map((column) => (
          <Column
            key={column.id}
            column={column}
            isCreating={isCreating}
            isWide={isWide}
            setIsCreating={setIsCreating}
            showSwipeTip={!column.cards.length && !isLoading}
          />
        ))}
      {!active && !isWide && <div className="min-w-[1px]" />}
      {!isWide && (
        <div className={cx('fixed left-3 flex gap-2', isIos ? 'bottom-[90px]' : 'bottom-14')}>
          <button
            className={cx('rounded-full border bg-wall-secondary-bg-light px-2 py-1 dark:bg-wall-secondary-bg-dark', {
              'border-emerald-500 hover:border-emerald-700 dark:border-emerald-700 dark:hover:border-emerald-600':
                !isScrolledStart,
              'dark:border-gray-600': isScrolledStart,
            })}
            onClick={handleLeftScroll}
            disabled={isScrolledStart}
          >
            <ArrowLeftIcon
              className={cx('h-5 w-5', {
                'text-gray-300': isScrolledStart,
              })}
            />
          </button>
          <button
            className={cx('rounded-full border bg-wall-secondary-bg-light px-2 py-1 dark:bg-wall-secondary-bg-dark', {
              'border-emerald-500 hover:border-emerald-700 dark:border-emerald-700 dark:hover:border-emerald-600':
                !isScrolledEnd,
              'dark:border-gray-600': isScrolledEnd,
            })}
            onClick={handleRightScroll}
            disabled={isScrolledEnd}
          >
            <ArrowRightIcon
              className={cx('h-5 w-5', {
                'text-gray-300': isScrolledEnd,
              })}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default Columns
