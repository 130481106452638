import { mainButton, useSignal } from '@telegram-apps/sdk-react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface SaveButtonProps {
  onSave: () => void
}

const useSaveButton = ({ onSave }: SaveButtonProps) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })
  const isVisible = useSignal(mainButton.isVisible)

  const hideSaveButton = useCallback(() => {
    mainButton.setParams({
      isVisible: false,
    })
  }, [])

  const showSaveButton = useCallback(() => {
    mainButton.setParams({
      isVisible: true,
    })
  }, [])

  const setLoading = useCallback((isLoading: boolean) => {
    mainButton.setParams({ isLoaderVisible: isLoading })
  }, [])

  useEffect(() => {
    mainButton.setParams({ backgroundColor: '#FF805C', text: t('createLabel'), isEnabled: true })
    const removeClickHandler = mainButton.onClick(onSave)

    showSaveButton()

    return () => {
      removeClickHandler()
      hideSaveButton()
    }
  }, [])

  return {
    hideSaveButton,
    showSaveButton,
    button: mainButton,
    isVisible,
    setLoading,
  }
}

export default useSaveButton
