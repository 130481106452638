import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { IColumn, Priority } from '../models/IProject'

const storage = createJSONStorage<string | null>(() => localStorage)
export const project = atomWithStorage<string | null>('project', null, storage, { getOnInit: true })

type Filter = {
  assignee_uuids: string[]
  owner_uuids: string[]
  status_uuids: string[]
  priority: Priority[]
}
export const filter = atom<Filter>({
  assignee_uuids: [],
  owner_uuids: [],
  status_uuids: [],
  priority: [],
})

export const columns = atom<IColumn[]>([])

export const handleTicketDrop = atom<(uuid: string, columnId: string) => void>()
