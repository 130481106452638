import { useMemo, memo, useCallback } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

import { Button, Drawer } from 'flowbite-react'
import Select from '@/components/Select'
import Datepicker from '@/components/Datepicker'
import { BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import DaysOfWeekSelector from '../DaysOfWeekSelector'

import { useToggleOpen } from '@/utils/hooks/useToggle'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { useGetMe } from '@/auth/queries'
import { getDayName, leftPadTime } from '@/utils'

import { ReminderFrequency } from '@/projects/models/IProject'
import { Language } from '@/i18n'

export type ReminderValue = {
  frequency: ReminderFrequency
  date?: Date | null
  day?: number[]
  hour?: number
  minute?: number
}

export interface ReminderProps {
  reminder?: ReminderValue | null
  isNowAvailable?: boolean
  isSet?: boolean
  label?: string
  onChange?: (reminder: ReminderValue | null) => void
}

export const Reminder: React.FC<ReminderProps> = ({
  reminder,
  isNowAvailable = true,
  isSet = true,
  label,
  onChange,
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.reminder' })
  const { isOpen, toggleOpen } = useToggleOpen()

  const me = useGetMe()

  const defaultValues = {
    frequency: reminder?.frequency || (isNowAvailable ? ReminderFrequency.NOW : ReminderFrequency.DAILY),
    day: reminder?.day || [new Date().getDay()],
    date: reminder?.date ? new Date(reminder?.date) : new Date(),
    hour: reminder?.hour || new Date().getHours(),
    minute: reminder?.minute || new Date().getMinutes(),
  }

  const { register, setValue, watch, getValues, reset } = useForm<ReminderValue>({
    defaultValues,
  })
  register('date')
  register('frequency')

  const frequency = watch('frequency')

  const isIos = useIsIos()

  const FREQUENCY_OPTIONS = useMemo(
    () =>
      [
        { value: ReminderFrequency.NOW, label: t('frequency.now') },
        { value: ReminderFrequency.ONCE, label: t('frequency.once') },
        { value: ReminderFrequency.DAILY, label: t('frequency.daily') },
        { value: ReminderFrequency.WEEKLY, label: t('frequency.weekly') },
      ].filter((item) => isNowAvailable || item.value !== ReminderFrequency.NOW),
    [t, isNowAvailable],
  )

  const handleClose = useCallback(() => {
    reset(defaultValues)
    toggleOpen()
  }, [reminder])

  const HOURS_OPTIONS = useMemo(
    () => Array.from({ length: 24 }, (_, i) => ({ value: i, label: leftPadTime(`${i}`) })),
    [],
  )
  const MINUTES_OPTIONS = useMemo(
    () => Array.from({ length: 60 }, (_, i) => ({ value: i, label: leftPadTime(`${i}`) })),
    [],
  )

  const labelValue = useMemo(() => {
    if (reminder?.frequency === ReminderFrequency.ONCE && reminder?.date) {
      const date = dayjs(reminder?.date)
        .set('hour', reminder?.hour || 0)
        .set('minute', reminder?.minute || 0)
      return date.isAfter(dayjs()) ? date.format('YYYY-MM-DD HH:mm') : null
    }
    if (reminder?.frequency === ReminderFrequency.DAILY) {
      return t('reminderDailyValueLabel', {
        hour: leftPadTime(reminder?.hour || 0),
        minute: leftPadTime(reminder?.minute || 0),
      })
    }
    if (reminder?.frequency === ReminderFrequency.WEEKLY) {
      return t('reminderWeeklyValueLabel', {
        day: reminder?.day?.map((day) => getDayName(day, me.data.language_code as Language, 'short'))?.join(', '),
        hour: leftPadTime(reminder?.hour || 0),
        minute: leftPadTime(reminder?.minute || 0),
      })
    }
  }, [reminder, t])

  return (
    <>
      <div className="flex flex-col gap-1">
        {label && <span className="text-xs">{label}</span>}
        <Button
          onClick={toggleOpen}
          size="xs"
          color={labelValue ? 'secondary' : 'gray'}
          type="button"
          className={twMerge(cx('[&>span]:items-center [&>span]:justify-between [&>span]:gap-1'))}
        >
          <BellIcon className="h-4 w-4" />
          {labelValue || t('title')}
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </div>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        className={cx('rounded-t-md', {
          'pb-10': isIos,
        })}
        position="bottom"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Drawer.Header title={t('title')} titleIcon={() => null} />
        <div className="flex flex-col gap-3">
          <div className="flex flex-wrap gap-2">
            <Select
              options={FREQUENCY_OPTIONS}
              title={t('frequencyLabel')}
              label={t('frequencyLabel')}
              value={frequency}
              onChange={(value) => setValue('frequency', value)}
            />
            {frequency === ReminderFrequency.ONCE && (
              <Datepicker
                onChange={(value) => setValue('date', value)}
                placeholder={t('dateLabel')}
                value={watch('date')}
                label={t('dateLabel')}
                allowPast={false}
              />
            )}
            {frequency === ReminderFrequency.WEEKLY && (
              <DaysOfWeekSelector
                title={t('dayLabel')}
                label={t('dayLabel')}
                value={watch('day')}
                onChange={(value) => setValue('day', value)}
              />
            )}
            <div className="flex flex-col gap-1">
              <span className="text-xs">{t('timeLabel')}</span>
              <div className="flex items-center gap-1">
                <Select
                  options={HOURS_OPTIONS}
                  title={t('hourLabel')}
                  value={watch('hour')}
                  onChange={(value) => setValue('hour', value)}
                  disabled={frequency === ReminderFrequency.NOW}
                />
                :
                <Select
                  options={MINUTES_OPTIONS}
                  title={t('minuteLabel')}
                  value={watch('minute')}
                  onChange={(value) => setValue('minute', value)}
                  disabled={frequency === ReminderFrequency.NOW}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2">
            {isSet && (
              <Button
                size="xs"
                color="gray"
                type="button"
                onClick={() => {
                  onChange?.(null)
                  toggleOpen()
                }}
              >
                {t('deleteLabel')}
              </Button>
            )}
            <Button
              size="xs"
              color="green"
              type="button"
              onClick={() => {
                if (frequency === ReminderFrequency.NOW) {
                  onChange?.({
                    frequency: ReminderFrequency.NOW,
                  })
                } else {
                  onChange?.(getValues())
                }
                toggleOpen()
              }}
            >
              {t(frequency === ReminderFrequency.NOW ? 'sendLabel' : 'saveLabel')}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default memo(Reminder)
