import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Toggle from '@/components/Toggle'

import { useUpdateProject } from '@/projects/queries'
import { IProjectResponse, IProjectUpdateRequest } from '@/projects/models/IProject'

export interface FeaturesProps {
  project: IProjectResponse
}

type Inputs = {
  backlog: boolean
  sprints: boolean
}

export const Features: React.FC<FeaturesProps> = ({ project }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const { t } = useTranslation('projects', { keyPrefix: 'settings.features' })

  const { mutateAsync: updateProject } = useUpdateProject(projectId, teamspaceId)

  const { watch, setValue } = useForm<Inputs>({
    defaultValues: {
      backlog: project.backlog_enabled,
      sprints: project.sprints_enabled,
    },
  })

  return (
    <div className="grid gap-2">
      <p className="text-sm">{t('title')}</p>
      <Toggle
        label={t('backlog')}
        text={t('backlogTip')}
        id="backlog"
        checked={watch('backlog')}
        color="green"
        onChange={(value) => {
          setValue('backlog', value)
          const body: Partial<IProjectUpdateRequest> = { backlog_enabled: value }
          if (!value) {
            setValue('sprints', false)
            body.sprints_enabled = false
          }
          updateProject(body)
        }}
      />
      <Toggle
        label={t('sprints')}
        text={t('sprintsTip')}
        id="sprints"
        checked={watch('sprints')}
        color="green"
        onChange={(value) => {
          setValue('sprints', value)
          if (value) {
            setValue('backlog', value)
          }
          updateProject({ sprints_enabled: value, backlog_enabled: value || project.backlog_enabled })
        }}
      />
    </div>
  )
}

export default Features
