import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { hapticFeedback } from '@telegram-apps/sdk-react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useIsMobileDevice } from './useIsMobileDevice'

export const useDnDHelpers = <
  C extends { id: string },
  T extends { id: string; cards: C[] } = { id: string; cards: C[] },
>({
  columns,
  setColumns,
  dropTop = false,
}: {
  columns: T[]
  setColumns: Dispatch<SetStateAction<T[]>>
  dropTop?: boolean
}) => {
  const isMobile = useIsMobileDevice()
  const [activeElement, setActiveElement] = useState<
    (C & { columnNumber: number; scrollTop: number; cardTopOffset: number }) | null
  >(null)

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null
    }
    if (columns.some((c) => c.id === unique)) {
      return columns.find((c) => c.id === unique) ?? null
    }
    const id = String(unique)
    const itemWithColumnId = columns.flatMap((c) => {
      const columnId = c.id
      return c.cards.map((i) => ({ itemId: i?.id, columnId }))
    })
    const columnId = itemWithColumnId.find((i) => i.itemId === id)?.columnId
    return columns.find((c) => c.id === columnId) ?? null
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over, delta } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)
    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return null
    }
    setColumns((prevState) => {
      const activeItems = activeColumn.cards
      const overItems = overColumn.cards
      const activeIndex = activeItems.findIndex((i) => i?.id === activeId)
      const overIndex = overItems.findIndex((i) => i?.id === overId)
      const newIndex = () => {
        if (dropTop) {
          return 0
        }
        const putOnBelowLastItem = overIndex === overItems.length - 1 && delta.y > 0
        const modifier = putOnBelowLastItem ? 1 : 0
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1
      }
      return prevState.map((c) => {
        if (c.id === activeColumn.id) {
          c.cards = activeItems.filter((i) => i?.id !== activeId)
          return c
        } else if (c.id === overColumn.id) {
          c.cards = [
            ...overItems.slice(0, newIndex()),
            activeItems[activeIndex],
            ...overItems.slice(newIndex(), overItems.length),
          ]
          return c
        } else {
          return c
        }
      })
    })
  }

  const handleDragEnd = async (event: DragEndEvent): Promise<T[] | null | undefined> => {
    const { active, over } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)

    if (!activeColumn || !overColumn || activeColumn !== overColumn) {
      return columns
    }

    const activeIndex = activeColumn.cards.findIndex((i) => i?.id === activeId)
    const overIndex = dropTop ? 0 : overColumn.cards.findIndex((i) => i?.id === overId)

    if (activeIndex !== overIndex) {
      hapticFeedback.notificationOccurred('success')

      return await new Promise((resolve) => {
        setColumns((prevState) => {
          const newState = prevState.map((column) => {
            if (column.id === activeColumn.id) {
              const cards = arrayMove([...overColumn.cards], activeIndex, overIndex)
              return { ...column, cards }
            } else {
              return column
            }
          })
          resolve(newState)
          return newState
        })
      })
    }

    return columns
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event

    const activeCard = columns
      .flatMap((c) => c.cards)
      .filter(Boolean)
      .find((c) => c.id === active.id)
    const columnNumber = columns
      .filter(Boolean)
      .findIndex((column) => column?.id === active.data.current?.sortable?.containerId)
    const container = document.getElementById(`column-${active.data.current?.sortable?.containerId}`) as HTMLElement
    const card = document.getElementById(`card-${active.id}`) as HTMLElement

    setActiveElement(
      activeCard
        ? {
            ...activeCard,
            columnNumber,
            scrollTop: container?.scrollTop || 0,
            cardTopOffset: card?.offsetTop || 0,
          }
        : null,
    )

    hapticFeedback.notificationOccurred('success')
  }

  const sensors = useSensors(
    useSensor(isMobile ? TouchSensor : PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return { handleDragOver, handleDragEnd, handleDragStart, activeElement, sensors }
}

export default useDnDHelpers
